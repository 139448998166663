export function initChat(name,email){
    console.log("init chat start")
    window["updateChatInfo"](name, email);
    console.log("init chat end")
    // var Tawk_API=Tawk_API||{};
    // Tawk_API.setAttributes({
    //     'name' : 'visitor',
    //     'email': 'visitor@email.com',
    //     'hash' : 'hash-value'
    // }, function (error) {});
    // var Tawk_API=window["Tawk_API"]||{}
    // Tawk_API.setAttributes({
    //   'name' : 'visitor',
    //   'email': 'visitor@email.com',
    //   'hash' : 'hash-value'
    //   }, function (error) {
    //     console.log("error", error)
    //   });
  }

  export function toogleChat(){
    window["toogleChat"]();
  }
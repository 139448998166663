import React from 'react'
import './banner.css'
export function Banner(props: any) {
    

    return (
        <>
            <div className="banner">
                <img style={{width:"100%"}} src="/images/banner.png" alt={"banner"}/>
            </div>
        </>
    )
}
